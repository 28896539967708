import CONFIG from "../../config/default.json";

export const GA_TRACKING_ID = CONFIG.GA_TRACKING;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = ({
  page_path,
  page_title,
}: {
  page_path: string;
  page_title: string;
}) => {
  if (window.gtag) {
    window.gtag("config", GA_TRACKING_ID, {
      page_title,
      page_path,
    });
  }
};

type GTagEvent = {
  action: string;
  category?: string;
  label?: string;
  value?: number;
  rest: Record<string, string>;
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value, rest }: GTagEvent) => {
  if (window.gtag) {
    window.gtag("event", action, {
      event_category: category,
      event_label: label,
      value: value,
      ...rest,
    });
  }
};
